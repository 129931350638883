<template>
  <nav class="navbar fixed-bottom navbar-light bg-light">
    <div class="container-fluid">
      <div>
        <div class="btn-group me-1">
          <button @click="zoomIn" class="btn btn-outline-secondary">
            <i class="bi bi-zoom-in"></i>
          </button>
          <button @click="zoomOut" class="btn btn-outline-secondary">
            <i class="bi bi-zoom-out"></i>
          </button>
          <button @click="zoomReset" class="btn btn-outline-secondary">
            <i class="bi bi-fullscreen-exit"></i>
          </button>
        </div>
        <div class="btn-group">
          <button class="btn btn-outline-secondary swiper-button-prev">
            <i class="bi bi-arrow-left"></i>
          </button>
          <button class="btn btn-outline-secondary swiper-button-next">
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
      <span class="navbar-text"></span>
      <div>
        <a
          href="#"
          @click.prevent="downloadPDF"
          class="btn btn-outline-secondary me-1"
          ><i class="bi bi-download"></i
        ></a>
        <button @click="closeViewer" class="btn btn-outline-secondary">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
  </nav>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div v-for="pageNum in numPages" :key="pageNum" class="swiper-slide">
        <div class="swiper-zoom-container">
          <canvas :id="`pdf-canvas-${pageNum}`" class="shadow-sm"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
if (typeof pdfjsLib !== 'undefined') {
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/build/pdf.worker.js'
}
import Swiper, { FreeMode, Navigation, Pagination, Zoom } from 'swiper'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/zoom'

export default {
  name: 'PDFViewer',
  components: {},

  emits: ['closeViewer'],

  props: {
    file_path: {
      type: String,
      required: true,
    },
    file_name: {
      type: String,
      required: false,
      default: 'file.pdf',
    },
  },

  data() {
    return {
      numPages: 0,
      scale: 1.5,
      swiper: null,
    }
  },

  mounted() {
    this.loadPdf()
  },

  watch: {
    async file_path() {
      this.resetSwiper()
      await this.clearCanvas()
      await this.loadPdf()
    },
  },

  methods: {
    clearCanvas() {
      const canvasList = document.querySelectorAll('.swiper-slide canvas')
      for (let i = 0; i < canvasList.length; i++) {
        const canvas = canvasList[i]
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
      }
    },
    async loadPdf() {
      this.swiper = this.initSwiper()
      const arrayBuffer = await this.getPdfArrayBuffer()
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise
      this.numPages = pdf.numPages
      for (let i = 1; i <= this.numPages; i++) {
        const page = await pdf.getPage(i)
        const viewport = page.getViewport({ scale: this.scale })
        const canvas = document.getElementById(`pdf-canvas-${i}`)
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width
        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise
        this.swiper.update()
      }
    },
    async getPdfArrayBuffer() {
      this.$store.commit('loading/set')
      try {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', this.file_path, true)
        xhr.responseType = 'arraybuffer'
        xhr.send()
        return await new Promise((resolve) => {
          xhr.onload = () => {
            resolve(xhr.response)
          }
        })
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.$store.commit('loading/clear')
      }
    },
    initSwiper() {
      const swiperOptions = {
        modules: [FreeMode, Navigation, Pagination, Zoom],
        freeMode: true,
        navigation: {
          disabledClass: 'disabled',
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.navbar-text',
          type: 'fraction',
        },
        zoom: {
          maxRatio: 5,
          minRatio: 1,
        },
      }
      return new Swiper('.swiper-container', swiperOptions)
    },
    resetSwiper() {
      if (this.swiper) {
        this.swiper.zoom.out()
        this.swiper.disable()
        this.swiper = null
      }
    },
    zoomIn() {
      const ratio = Math.min(this.swiper.zoom.scale + 0.5, 5)
      this.swiper.zoom.in(ratio)
    },
    zoomOut() {
      const ratio = Math.max(this.swiper.zoom.scale - 0.5, 1)
      this.swiper.zoom.in(ratio)
    },
    zoomReset() {
      this.swiper.zoom.out()
    },
    closeViewer() {
      this.$emit('closeViewer')
    },
    async downloadPDF() {
      this.$store.commit('loading/set')
      try {
        const response = await axios({
          url: this.file_path,
          method: 'GET',
          responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.file_name)
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.commit('loading/clear')
      }
    },
  },
}
</script>

<style scoped>
.swiper-container {
  height: calc(100vh - 108px);
  padding-top: 54px;
  padding-bottom: 54px;
}

.swiper-slide {
  height: 100%;
}
</style>
