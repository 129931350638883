import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import ProductList from '@/views/ProductList.vue'
import ProductDetail from '@/views/ProductDetail.vue'
import StockFolder from '@/views/StockFolder.vue'

// store
import store from '@/store'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/list',
    component: ProductList,
    name: 'ProductList',
    meta: { requiresAuth: true },
  },
  {
    path: '/detail/:id([0-9]+?)',
    component: ProductDetail,
    name: 'ProductDetail',
    props: (route) => ({ id: Number(route.params.id) }),
    meta: { requiresAuth: true },
  },
  {
    path: '/folder',
    component: StockFolder,
    name: 'StockFolder',
    meta: { requiresAuth: true, requiresStockPerm: true },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/is_logged_in']) {
    if (store.getters['auth/is_token']) {
      store.dispatch('auth/renew')
    } else {
      router.replace({
        name: 'Login',
        query: { redirect: to.fullPath },
      })
    }
  }

  if (to.meta.requiresStockPerm && !store.getters['auth/has_stock_perm']) {
    router.replace({
      name: 'Home',
    })
  }

  next()
})

export default router
