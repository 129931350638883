import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import product from './modules/product'
import product_file from './modules/product_file'
import stock from './modules/stock'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('product/clearAll', null, { root: true })
      dispatch('product_file/clearAll', null, { root: true })
      dispatch('stock/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('product/clearAll', null, { root: true })
      dispatch('product_file/clearAll', null, { root: true })
      dispatch('stock/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    product,
    product_file,
    stock,
  },
})

export default store
