<template>
  <main class="d-flex align-items-center vh-100 bg-light">
    <div class="card-wrapper">
      <div class="mb-3 text-center">
        <img src="@/assets/logo.svg" class="img-fluid" />
      </div>
      <div class="card">
        <div class="card-body">
          <form>
            <div class="mb-4">
              <label class="text-muted mb-1" for="username">ユーザー名</label>
              <input
                v-model="form_data.username"
                type="text"
                class="form-control"
                id="username"
              />
            </div>
            <div class="mb-4">
              <label class="text-muted mb-1" for="password">パスワード</label>
              <input
                v-model="form_data.password"
                type="password"
                class="form-control"
                id="password"
              />
            </div>

            <button
              class="w-100 btn btn-primary"
              v-on:click.prevent="formSubmit"
            >
              ログイン
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {},

  data() {
    return {
      form_data: {},
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async formSubmit() {
      try {
        await this.login(this.form_data)
        const redirect = this.$route.query.redirect || '/'
        this.$router.replace(redirect)
      } catch (e) {
        if (e.response?.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: 'ユーザー名またはパスワードが間違っています。',
          })
        }
      }
    },
  },
}
</script>

<style scoped>
.card-wrapper {
  margin: 0 auto;
  max-width: 90%;
  width: 350px;
}
</style>
