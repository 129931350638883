<template>
  <Navbar />
  <main class="container-fluid mt-3">
    <p>
      <router-link
        class="btn btn-outline-dark"
        :to="{
          name: 'ProductList',
        }"
        >検索結果に戻る</router-link
      >
    </p>
    <div class="row">
      <div class="col-sm">
        <h3 class="mb-3">{{ product.product_code }}</h3>
        <dl class="row">
          <dt class="col-sm-3">品番</dt>
          <dd class="col-sm-9">{{ product.product_number }}</dd>
          <dt class="col-sm-3">品名</dt>
          <dd class="col-sm-9">{{ product.product_name }}</dd>
        </dl>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="card-header">メモ</div>
          <div class="card-body bg-light">
            <textarea
              v-model="product.memo"
              class="form-control bg-white"
              id="productMemo"
              rows="4"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- files -->
    <div
      v-if="product.product_files && product.product_files.length > 0"
      class="table-responsive"
    >
      <table class="table table-bordered mt-4">
        <thead>
          <tr class="text-center">
            <th scope="col" class="col-sm-2">種類</th>
            <th scope="col" class="col-sm-4">ファイル名</th>
            <th scope="col" class="col-sm-2">ファイルタイプ</th>
            <th scope="col" class="col-sm-2">ファイルサイズ</th>
            <th scope="col" class="col-sm"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in product.product_files" :key="file.id">
            <th scope="row">{{ file.file_category }}</th>
            <td>{{ file.file_name }}</td>
            <td class="text-center">{{ file.file_type }}</td>
            <td class="text-end">{{ $filters.bytesToSize(file.file_size) }}</td>
            <td class="text-end">
              <a
                v-if="file.file_path && file.file_type.match(/pdf/)"
                href="#"
                v-on:click.prevent="startPDFViewer(file)"
                >表示</a
              >
              <span v-else class="text-muted">表示</span>
              <span v-if="has_stock_perm">
                ｜
                <a
                  v-if="file.file_path"
                  href="#"
                  v-on:click.prevent="insertFile(file)"
                  >選択</a
                >
                <span v-else class="text-muted">選択</span>
              </span>
              <span v-if="has_download_perm">
                ｜
                <a
                  v-if="file.file_path"
                  href="#"
                  v-on:click.prevent="downloadFile(file.id)"
                  >DL</a
                >
                <span v-else class="text-muted">DL</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="mt-4">この製品に紐づいたファイルはありません。</p>

    <!-- products -->
    <h4 class="mt-4">関連商品・部品</h4>
    <div
      v-if="product.related_products && product.related_products.length > 0"
      class="table-responsive"
    >
      <table class="table mt-3">
        <thead>
          <tr>
            <th scope="col" class="col-sm-2">コードナンバー</th>
            <th scope="col" class="col-sm-4">品番</th>
            <th scope="col" class="col-sm">品名</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in product.related_products" :key="product.id">
            <th scope="row">
              <router-link
                :to="{
                  name: 'ProductDetail',
                  params: { id: product.id },
                }"
                >{{ product.product_code }}</router-link
              >
            </th>
            <td>{{ product.product_number }}</td>
            <td>{{ product.product_name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="mt-3">この製品に関連する商品・部品はありません。</p>

    <!-- Modal -->
    <div class="modal fade" id="stockModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            {{ message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              閉じる
            </button>
            <button class="btn btn-primary" v-on:click.prevent="goToFolder()">
              フォルダを見る
            </button>
          </div>
        </div>
      </div>
    </div>

    <PDFJSViewer ref="pdfViewer" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import Navbar from '@/components/Navbar.vue'
import PDFJSViewer from '@/components/PDFJSViewer'

export default {
  name: 'FileDetail',
  components: {
    Navbar,
    PDFJSViewer,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      message: '',
    }
  },
  computed: {
    ...mapGetters({
      product: 'product/detail',
      has_stock_perm: 'auth/has_stock_perm',
      has_download_perm: 'auth/has_download_perm',
    }),
  },

  async created() {
    await this.getData(this.id).catch(() => {
      window.setTimeout(() => {
        this.$router.push({
          name: 'Home',
        })
      }, this.$store.getters['alert/timeout'])
    })
  },

  watch: {
    // 同ページ遷移のため変更を監視
    $route(to, from) {
      if (to.params.id) {
        this.getData(to.params.id)
      }
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById('stockModal'))
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      getData: 'product/fetchData',
      insertToFolder: 'stock/insertToFolder',
      getFileBlog: 'product_file/getFileBlog',
    }),
    insertFile(file) {
      this.insertToFolder(file)
        .then((file) => {
          this.message = file.file_name + ' をストックフォルダに入れました。'
        })
        .catch(() => {
          this.message =
            file.file_name + ' はすでにストックフォルダに入っています。'
        })
        .finally(() => this.modal.show())
    },
    goToFolder() {
      this.$router.push({ name: 'StockFolder' })
    },
    startPDFViewer(file) {
      this.$refs.pdfViewer.showModal(file)
    },
    async downloadFile(id) {
      try {
        const response = await this.getFileBlog(id)
        const contentDisposition = response.headers['content-disposition']
        const encodedFileName = contentDisposition
          .split("filename*=UTF-8''")[1]
          .replace(/"/g, '')
        const fileName = decodeURIComponent(encodedFileName)

        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > thead > tr > th {
  white-space: nowrap;
}
</style>
