<template>
  <div class="modal fade" id="canvasModal">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <PDFViewer
          v-if="showPdf"
          :file_path="file.file_path"
          :file_name="file.file_name"
          @close-viewer="hideModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import PDFViewer from '@/components/PDFViewer'

export default {
  name: 'PDFJSViewer',
  components: {
    PDFViewer,
  },

  data() {
    return {
      modal: null,
      file: {},
      showPdf: false,
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('canvasModal'))
    document
      .getElementById('canvasModal')
      .addEventListener('shown.bs.modal', () => {
        this.showPdf = true
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },
  methods: {
    showModal(file) {
      if (file) {
        this.file = file
        this.modal.show()
      }
    },
    hideModal() {
      this.modal.hide()
    },
  },
}
</script>
