<template>
  <Navbar />
  <main class="container-fluid mt-3">
    <h3>検索結果</h3>
    <form>
      <div class="card">
        <div class="card-body">
          <div class="row g-3">
            <label for="productCode" class="col-sm col-form-label"
              ><small>コードナンバー</small></label
            >
            <div class="col-sm-3">
              <input
                v-model="query.product_code"
                type="text"
                class="form-control"
                id="productCode"
              />
            </div>
            <label for="productNumber" class="col-sm col-form-label"
              ><small>品番・品名</small></label
            >
            <div class="col-sm-3">
              <input
                v-model="query.product_number"
                type="text"
                class="form-control"
                id="productNumber"
              />
            </div>
            <div class="col-sm">
              <button
                class="w-100 btn btn-primary"
                type="submit"
                v-on:click.prevent="getQueryResult"
              >
                検索
              </button>
            </div>
            <div class="col-sm">
              <button
                class="w-100 btn btn-secondary"
                type="button"
                v-on:click.prevent="resetQuery"
              >
                リセット
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- products -->
    <div v-if="products && products.length > 0">
      <div class="table-responsive">
        <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">コードナンバー</th>
              <th scope="col" class="col-sm-4">品番</th>
              <th scope="col" class="col-sm">品名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.id">
              <th scope="row">
                <router-link
                  :to="{
                    name: 'ProductDetail',
                    params: { id: product.id },
                  }"
                  >{{ product.product_code }}</router-link
                >
              </th>
              <td>{{ product.product_number }}</td>
              <td>{{ product.product_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :current_page="current_page"
        :next="next"
        :prev="prev"
        :total_pages="total_pages"
        :page_range="7"
        @move-page="movePage"
      />
    </div>
    <p v-else class="mt-3">検索に該当するファイルはありません。</p>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'FileList',
  components: {
    Navbar,
    Pagination,
  },

  data() {
    return {
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      products: 'product/products',
      count: 'product/count',
      prev: 'product/prev',
      next: 'product/next',
      current_page: 'product/current_page',
      total_pages: 'product/total_pages',
      query_params: 'product/query_params',
    }),
  },

  async created() {
    await this.getData()
    this.query = this.query_params
  },

  methods: {
    ...mapActions({
      setQuery: 'product/setQuery',
      getData: 'product/fetchAll',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
  },
}
</script>

<style scoped>
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > thead > tr > th {
  white-space: nowrap;
}
</style>
