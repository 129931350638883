<template>
  <div class="modal fade" tabindex="-1" role="dialog" :id="modal_id">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content rounded-3 shadow">
        <div class="modal-body p-4 text-center">
          <h5 class="mb-1">{{ confirm.title }}</h5>
          <p class="mb-0" style="white-space: pre-wrap; word-wrap: break-word">
            {{ confirm.message }}
          </p>
        </div>
        <div class="modal-footer flex-nowrap p-0">
          <button
            type="button"
            class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-end"
            data-bs-dismiss="modal"
          >
            いいえ
          </button>
          <button
            type="button"
            class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
            id="confirmTrue"
            @click.prevent="confirmTrue"
          >
            <strong>はい</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'AppConfirm',

  emits: ['hiddenModal', 'confirmTrue'],

  data() {
    return {
      modal: null,
      confirm: {
        title: '',
        message: '',
      },
    }
  },
  computed: {
    modal_id: function () {
      let chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let rand_str = ''
      for (let i = 0; i < 8; i++) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return 'confirm-' + rand_str
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modal_id))
    document
      .getElementById(this.modal_id)
      .addEventListener('hidden.bs.modal', () => {
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    showConfirm(title, message) {
      return new Promise((resolve) => {
        this.confirm = {
          title: title,
          message: message,
        }
        this.modal.show()
        resolve()
      })
    },
    hideConfirm() {
      this.modal.hide()
    },
    confirmTrue() {
      this.$emit('confirmTrue')
      this.modal.hide()
    },
  },
}
</script>
