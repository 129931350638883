import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  files: [],
  subjects: [],
  signatures: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  files: (state) => state.files,
  subjects: (state) => state.subjects,
  signatures: (state) => state.signatures,
  is_stocked: (state) => !!state.files.length,
  total_file_size: (state) =>
    state.files.reduce((sum, obj) => sum + parseFloat(obj.file_size), 0),
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  insertFile: (state, file) => state.files.push(file),
  removeFile: (state, file) =>
    (state.files = state.files.filter((v) => file.id != v.id)),
  clearFile: (state) => (state.files = []),
  setSubject: (state, subjects) => (state.subjects = subjects),
  clearSubject: (state) => (state.subjects = []),
  setSignature: (state, signatures) => (state.signatures = signatures),
  clearSignature: (state) => (state.signatures = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearFile')
      commit('clearSubject')
      commit('clearSignature')
      resolve()
    })
  },
  insertToFolder({ state, commit }, file) {
    return new Promise((resolve, reject) => {
      const stock_file = state.files.find(
        (stock_file) => stock_file.id === file.id
      )

      if (!stock_file) {
        commit('insertFile', file)
        resolve(file)
      } else {
        reject(file)
      }
    })
  },
  removeFromFolder({ state, commit }, file) {
    return new Promise((resolve, reject) => {
      const stock_file = state.files.find(
        (stock_file) => stock_file.id === file.id
      )

      if (stock_file) {
        commit('removeFile', file)
        resolve(file)
      } else {
        reject(file)
      }
    })
  },
  async sendMail({ commit }, form_data) {
    await api.post('mails/', form_data)
    commit('clearFile')
  },
  async getSubject({ commit }) {
    const response = await api.get('subjects/')
    commit('setSubject', response.data)
  },
  async getSignature({ commit }) {
    const response = await api.get('signatures/')
    commit('setSignature', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
