import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Import animation.css
import 'animate.css/animate.min.css'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
})
app.mount('#app')

app.config.globalProperties.$filters = {
  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  },
}
