<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
  />

  <div v-else>
    <Navbar />
    <main class="home d-flex align-items-center vh-100">
      <div class="card-wrapper">
        <div class="card">
          <div class="card-header">検索</div>
          <div class="card-body">
            <form>
              <div class="mb-4 row">
                <label class="text-muted mb-1" for="productCode"
                  >コードナンバー</label
                >
                <div class="col">
                  <input
                    v-model="query.product_code"
                    type="text"
                    class="form-control w-100"
                    id="productCode"
                  />
                </div>
                <div v-if="has_QR_perm" class="col-4">
                  <button
                    type="button"
                    class="btn btn-light w-100"
                    v-on:click.prevent="startCodeCamera"
                  >
                    <i class="bi bi-qr-code"></i>
                  </button>
                </div>
              </div>
              <div class="mb-4">
                <label class="text-muted mb-1" for="productNumber"
                  >品番・品名</label
                >
                <input
                  v-model="query.product_number"
                  type="text"
                  class="form-control"
                  id="productNumber"
                />
              </div>

              <div class="row gx-2">
                <div class="col">
                  <button
                    type="button"
                    class="w-100 btn btn-secondary"
                    v-on:click.prevent="resetQuery"
                  >
                    リセット
                  </button>
                </div>
                <div class="col">
                  <button
                    type="submit"
                    class="w-100 btn btn-primary"
                    v-on:click.prevent="getProducts"
                  >
                    検索
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { trimToWhiteSpace } from '@/utils/stringUtils.js'
import Navbar from '@/components/Navbar'
import CodeReader from '@/components/AppCodeReader.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    CodeReader,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
    }
  },
  computed: {
    ...mapGetters({
      products: 'product/products',
      query_params: 'product/query_params',
      has_QR_perm: 'auth/has_QR_perm',
    }),
  },

  created() {
    this.query = this.query_params
  },

  methods: {
    trimToWhiteSpace,
    ...mapActions({
      setQuery: 'product/setQuery',
      getData: 'product/fetchAll',
    }),
    getProducts() {
      this.setQuery(this.query).then(() => {
        // データ取得
        // 結果1件ならば詳細画面に移動
        this.getData().then(() => {
          if (this.products.length === 1) {
            this.$router.push({
              name: 'ProductDetail',
              params: { id: this.products[0].id },
            })
          } else {
            this.$router.push({
              name: 'ProductList',
            })
          }
        })
      })
    },
    resetQuery() {
      this.query = {}
    },
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.query.product_code = this.trimToWhiteSpace(result)
      this.showCodeCamera = false
    },
  },
}
</script>

<style scoped>
main.home {
  margin-top: -54px;
}

.card-wrapper {
  margin: 0 auto;
  max-width: 90%;
  width: 350px;
}
</style>
