<template>
  <nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand mb-0 h1 me-auto">{{ title }}</span>
      <ul class="navbar-nav mb-0">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'Home',
            }"
            ><i class="bi bi-search"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="has_stock_perm"
            class="nav-link"
            :to="{
              name: 'StockFolder',
            }"
            ><span class="position-relative">
              <i class="bi bi-folder2"></i>
              <span
                v-if="is_stocked"
                class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
              >
                <span class="visually-hidden">attachment files</span>
              </span>
            </span></router-link
          >
        </li>
        <li class="nav-item">
          <a v-on:click.prevent="confirmLogout()" class="nav-link" href="#"
            ><i class="bi bi-box-arrow-right"></i
          ></a>
        </li>
      </ul>
    </div>
  </nav>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Confirm from '@/components/AppConfirm.vue'

export default {
  name: 'Navbar',
  components: {
    Confirm,
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },
  computed: {
    ...mapGetters({
      has_stock_perm: 'auth/has_stock_perm',
      is_stocked: 'stock/is_stocked',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', 'ログアウトしますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'Login',
      })
    },
  },
}
</script>

<style scoped>
.nav-item {
  margin-left: 0.5rem;
}

.bi::before {
  font-size: 1.5rem;
}
</style>
