<template>
  <nav aria-label="Page navigation" class="mt-5">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="this.prev ? '' : 'disabled'">
        <a class="page-link" @click.prevent="movePrev()"
          ><span aria-hidden="true">&laquo;</span></a
        >
      </li>
      <li v-for="page in pages" :key="page" :class="getPageClass(page)">
        <a
          class="page-link"
          href="#"
          v-text="page"
          @click.prevent="movePage(page)"
        ></a>
      </li>
      <li class="page-item" :class="this.next ? '' : 'disabled'">
        <a class="page-link" @click.prevent="moveNext()"
          ><span aria-hidden="true">&raquo;</span></a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    current_page: Number,
    next: String,
    prev: String,
    total_pages: Number,
    page_range: Number,
  },
  emits: ['movePage'],

  computed: {
    pages() {
      let pages = []
      let first_page = 1
      let last_page = 1
      const half_page_range = Math.floor(this.page_range / 2)

      if (this.total_pages > this.page_range) {
        // 現在のページが指定の半分より大きい場合は現在のページから指定の半分を差し引く
        if (this.current_page > half_page_range) {
          first_page = this.current_page - half_page_range
        }
        // 最後のページがトータルページ数を超える場合は最後のページにする
        if (this.current_page + half_page_range >= this.total_pages) {
          first_page = this.total_pages - this.page_range + 1
        }
        last_page = first_page + this.page_range
      } else {
        last_page = this.total_pages + 1
      }
      for (let i = first_page; i < last_page; i++) {
        pages.push(i)
      }
      return pages
    },
  },

  methods: {
    movePrev() {
      if (this.prev) {
        const prev_page = this.current_page - 1
        this.movePage(prev_page)
      }
    },
    moveNext() {
      if (this.next) {
        const next_page = this.current_page + 1
        this.movePage(next_page)
      }
    },
    movePage(page) {
      this.$emit('movePage', page)
    },
    getPageClass(page) {
      let classes = ['page-item']
      if (this.current_page == page) {
        classes.push('active')
      }
      return classes
    },
  },
}
</script>
