<template>
  <Navbar />
  <main class="container-fluid my-3">
    <h3>ストックフォルダ</h3>
    <div class="row">
      <div class="col-sm-6 mb-3">
        <div class="card mb-3">
          <ul v-if="is_stocked" class="list-group list-group-flush">
            <li
              v-for="file in files"
              :key="file.id"
              class="list-group-item d-flex justify-content-between"
            >
              <div class="text-break">
                <span class="badge bg-dark me-3">{{ file.file_category }}</span>
                <a
                  v-if="file.file_path && file.file_type.match(/pdf/)"
                  href="#"
                  v-on:click.prevent="startPDFViewer(file)"
                  >{{ file.file_name }}</a
                >
                <span v-else>{{ file.file_name }}</span>
              </div>
              <div class="text-nowrap">
                <small class="text-muted me-2 me-md-3">{{
                  $filters.bytesToSize(file.file_size)
                }}</small>
                <button
                  type="button"
                  class="btn btn-link btn-sm text-secondary"
                  v-on:click.prevent="removeStock(file)"
                >
                  <i class="bi bi-trash-fill"></i>
                </button>
              </div>
            </li>
          </ul>
          <div v-else class="card-body">ファイルはありません。</div>
        </div>
        <div class="d-sm-flex justify-content-between">
          <p>
            <i class="bi bi-plus-circle me-1"></i
            ><router-link
              class="text-muted"
              :to="{
                name: 'Home',
              }"
              >ファイルを追加する</router-link
            >
          </p>
          <p
            :class="{ 'text-danger fw-bold': total_file_size >= max_file_size }"
          >
            {{ $filters.bytesToSize(total_file_size) }}
          </p>
        </div>
        <div class="alert alert-danger mb-0" role="alert">
          送信できるメールのサイズは1通あたり最大
          {{ $filters.bytesToSize(max_file_size) }}です。<br /><span
            class="small"
            >※ 添付ファイルが
            {{
              $filters.bytesToSize(max_file_size)
            }}以下でもメール全体のサイズが超過する場合があります。</span
          >
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">メール送信</div>
          <div class="card-body">
            <form>
              <div class="mb-2">
                <label for="to" class="form-label"
                  >宛先<small
                    :class="{ 'd-none': validation.to }"
                    class="ms-2 text-danger"
                    >{{ error_message.to }}</small
                  ></label
                >
                <div class="row">
                  <div
                    v-for="(toEmail, index) in toList"
                    :key="index"
                    class="col-10"
                  >
                    <div class="input-group mb-2">
                      <input
                        v-model="toList[index]"
                        type="email"
                        placeholder="to@example.com"
                        class="form-control"
                      />
                      <span
                        class="input-group-text bg-transparent"
                        @click="removeTo(index)"
                        ><i class="bi bi-trash3 link-secondary"></i
                      ></span>
                    </div>
                  </div>
                  <div class="col-2 text-end">
                    <span class="form-control-plaintext" @click="addTo"
                      ><i class="bi bi-plus-circle-fill link-secondary"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label for="bcc" class="form-label"
                  >Bcc<small
                    :class="{ 'd-none': validation.bcc }"
                    class="ms-2 text-danger"
                    >{{ error_message.bcc }}</small
                  ></label
                >
                <div class="row">
                  <div
                    v-for="(bccEmail, index) in bccList"
                    :key="index"
                    class="col-10"
                  >
                    <div class="input-group mb-2">
                      <input
                        v-model="bccList[index]"
                        type="email"
                        placeholder="bcc@example.com"
                        class="form-control"
                      />
                      <span
                        class="input-group-text bg-transparent"
                        @click="removeBcc(index)"
                        ><i class="bi bi-trash3 link-secondary"></i
                      ></span>
                    </div>
                  </div>
                  <div class="col-2 text-end">
                    <span class="form-control-plaintext" @click="addBcc"
                      ><i class="bi bi-plus-circle-fill link-secondary"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label for="subject" class="form-label"
                  >件名<small
                    :class="{ 'd-none': validation.subject }"
                    class="ms-2 text-danger"
                    >{{ error_message.subject }}</small
                  ></label
                >
                <select
                  v-model="subject"
                  class="form-select text-secondary mb-2"
                >
                  <option value="">登録済みの件名を選択</option>
                  <option
                    v-for="subject in subjects"
                    :key="subject"
                    :value="subject.subject"
                  >
                    {{ subject.subject }}
                  </option>
                </select>
                <input
                  v-model="mail_form.subject"
                  type="text"
                  class="form-control"
                  id="subject"
                />
              </div>
              <div class="mb-2">
                <label for="message" class="form-label"
                  >本文<small
                    :class="{ 'd-none': validation.message }"
                    class="ms-2 text-danger"
                    >{{ error_message.message }}</small
                  ></label
                >
                <textarea
                  v-model="mail_form.message"
                  class="form-control"
                  id="message"
                  rows="5"
                ></textarea>
              </div>
              <div class="mb-0">
                <select
                  v-model="signature"
                  @change="selectedSignature"
                  class="form-select text-secondary mb-2"
                >
                  <option value="">登録済みの署名を選択</option>
                  <option
                    v-for="signature in signatures"
                    :key="signature"
                    :value="signature.signature"
                  >
                    {{ signature.name }}
                  </option>
                </select>
                <textarea
                  v-model="mail_form.signature"
                  class="form-control"
                  id="signature"
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
          <div class="card-footer bg-white text-end">
            <button
              v-on:click.prevent="confirmCancel"
              type="button"
              class="btn btn-secondary"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click.prevent="sendEmail"
              :disabled="!isValid"
            >
              送信
            </button>
          </div>
        </div>
      </div>
    </div>

    <PDFJSViewer ref="pdfViewer" />
  </main>
  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Confirm from '@/components/AppConfirm.vue'
import Navbar from '@/components/Navbar.vue'
import PDFJSViewer from '@/components/PDFJSViewer'

export default {
  name: 'StockFolder',
  components: {
    Confirm,
    Navbar,
    PDFJSViewer,
  },

  data() {
    return {
      mail_form: {},
      toList: [''],
      bccList: [''],
      subject: '',
      signature: '',
      error_message: {
        to: '* 宛先を入力してください',
        bcc: '* メールアドレスの形式ではありません',
        subject: '* 件名を入力してください',
        message: '* 本文を入力してください',
      },
      max_file_size: 20971520,
    }
  },
  computed: {
    ...mapGetters({
      files: 'stock/files',
      subjects: 'stock/subjects',
      signatures: 'stock/signatures',
      is_stocked: 'stock/is_stocked',
      total_file_size: 'stock/total_file_size',
      userData: 'auth/userData',
    }),
    validation() {
      const form = this.mail_form
      const email_pattern =
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/

      return {
        to: (() => {
          const toList = this.toList.filter(Boolean)
          if (toList.length === 0) {
            return false
          }

          for (let i = 0; i < toList.length; i++) {
            if (!toList[i].match(email_pattern)) {
              return false
            }
          }
          return true
        })(),
        bcc: (() => {
          const bccList = this.bccList.filter(Boolean)
          for (let i = 0; i < bccList.length; i++) {
            if (!bccList[i].match(email_pattern)) {
              return false
            }
          }
          return true
        })(),
        subject: !!form.subject,
        message: !!form.message,
      }
    },
    isValid() {
      const validation = this.validation
      return (
        Object.keys(validation).every((key) => {
          return validation[key]
        }) &&
        this.is_stocked &&
        this.total_file_size <= this.max_file_size
      )
    },
  },

  async created() {
    await this.getSubject()
    await this.getSignature()
    await this.initSignature()
  },

  mounted() {
    const email = this.userData('email')
    if (email) {
      this.bccList.push(email)
    }
  },
  beforeUnmount() {
    this.clearForm()
  },

  watch: {
    subject(newValue, oldValue) {
      this.mail_form.subject = newValue
    },
    signature(newValue, oldValue) {
      this.mail_form.signature = newValue
    },
  },
  methods: {
    ...mapActions({
      removeFromFolder: 'stock/removeFromFolder',
      clearFolder: 'stock/clearAll',
      sendMail: 'stock/sendMail',
      getSubject: 'stock/getSubject',
      getSignature: 'stock/getSignature',
    }),
    initSignature() {
      if (this.signatures.length > 0) {
        this.mail_form.signature = this.signatures[0].signature
      }
    },
    removeStock(file) {
      this.removeFromFolder(file)
    },
    clearForm() {
      this.mail_form = {}
      this.toList = ['']
      this.bccList = ['']
      this.subject = ''
      this.signature = ''
    },
    sendEmail() {
      this.mail_form.to = this.listToString(this.toList)
      this.mail_form.bcc = this.listToString(this.bccList)
      if (this.mail_form.signature) {
        this.mail_form.message =
          this.mail_form.message + '\n\n' + this.mail_form.signature
      }
      this.sendMail(Object.assign(this.mail_form, { details: this.files }))
      this.$store.dispatch('alert/setSuccessMessage', {
        message: '送信しました',
      })
      this.clearForm()
    },
    addTo() {
      this.toList.push('')
    },
    removeTo(index) {
      this.toList.splice(index, 1)
    },
    addBcc() {
      this.bccList.push('')
    },
    removeBcc(index) {
      this.bccList.splice(index, 1)
    },
    listToString(listData) {
      if (Array.isArray(listData)) {
        return listData.filter(Boolean).join(',')
      }
    },
    startPDFViewer(file) {
      this.$refs.pdfViewer.showModal(file)
    },
    confirmCancel() {
      this.$refs.confirm.showConfirm(
        '',
        'キャンセルしますか？\nストックフォルダのファイルも\nリセットされます。'
      )
    },
    async confirmTrue() {
      this.clearFolder().then(() => {
        this.$router.push({ name: 'Home' })
      })
    },
  },
}
</script>

<style scoped>
.card-footer > * {
  margin: 0.25rem;
}
</style>
